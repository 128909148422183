.acc-setting-form-input {
  background-color: transparent !important;
  border: 1px solid var(--border-light) !important;
  border-radius: 18px !important;
  font-size: 1.042vw;
}

.acc-setting-password-input {
  border-radius: 18px 0px 0px 18px !important;
  border-right: unset !important;
}

.acc-setting-card {
  padding: 1.5vw;
  // height: 28em !important;
}

.country-search-box {
  position: relative;
}

.dropdown-menu {
  --bs-dropdown-min-width: 19rem !important;
  padding: 7px 8px !important;

  @media screen and (max-width: 767px) {
    --bs-dropdown-min-width: 16rem !important;
  }

  .search-icon-div {
    position: absolute;
    top: 50%;
    left: 0.5rem;
    transform: translateY(-50%);
    display: flex;

    svg {
      vertical-align: middle;
      vertical-align: -webkit-baseline-middle;
    }
  }

  .search-form-input {
    background-color: var(--background-gray) !important;
    padding-left: 36px;
    border: 1px solid var(--border-light);
    height: 45px;
    border-radius: 12px;
    margin-bottom: 0.5rem;
  }

  .country-options {
    margin: 0;
    padding: 10px;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      font-size: 14px;
    }

    &:hover {
      background: color-mix(in srgb, var(--color-primary) 20%, transparent);
      border-radius: 18px;
    }
  }

  .country-list {
    max-height: calc(30vh);
    overflow-x: hidden;
    overflow-y: scroll;
    display: block;
  }
}

.invisible {
  visibility: hidden;
}

.btn-custom {
  border-radius: 100px;
  background: #E6E6E6 !important;
}

.acc-setting-flex-container {
  display: flex;
  flex-direction: column;
  gap: 70px;
}


.mobile-number-border {
  border-radius: 14px !important;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  width: 120px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  // padding: unset !important;
  padding-left: 8px !important;
  border-right: unset !important;
  border-color: var(--color-dark-muted) !important;
  padding: 10px 12px;
  color: var(--color-dark);
  font-family: var(--sf-pro-regular);
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.28px;
  background: transparent;

  @media screen and (max-width: 1399px) {
    font-size: 14px !important;
  }

  @media (max-width: 767px) {
    padding: 6px 14px;
    line-height: 24px;
    letter-spacing: -0.28px;
    // border: none;
  }


  .mobile-selection {
    border: unset !important;
    border-radius: unset;
    border-right: 1px solid var(--color-dark) !important;
    padding: unset;
    padding-right: 8px;

    &:focus {
      border-color: none;
      color: unset;
      background-color: unset;
    }
  }
}


.mobile-input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  // border-left: unset !important;
  font-family: var(--sf-pro-regular);
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.28px;
  background: transparent;


  @media screen and (max-width: 1399px) {
    font-size: 14px !important;
  }
}

//   .row {
//     display: flex;
//     flex-wrap: wrap;
//   }

//   .col-md-12, .col-lg-6 {
//     display: flex;
//     flex-direction: column;
//   }

.row.special-row {
  display: flex;
  flex-wrap: wrap;

  .col-md-12,
  .col-lg-6 {
    display: flex;
    flex-direction: column;
  }
}

.mb-6 {
  margin-bottom: 4rem !important;
}

.mt-6 {
  margin-top: 4rem !important;
}

.h-45 {
  height: 45px !important;
}


.account-settings {
  .account-settings-container {
    height: calc(20vw + 10.95rem);
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  .form-container {
    // height: calc(14vw + 14rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .form-heading {
    font-size: 1.46vw;
    font-style: normal;
    // font-weight: 600;
    line-height: 135.714%;
    letter-spacing: -0.56px;
  }

  .form-subheading {
    font-size: 0.94vw;
  }

  .profile-image {
    height: 3.5vw;
    width: 3.5vw;
  }

  .form-input-label {
    font-size: 1.042vw;
    font-style: normal;
    font-weight: 500;
    line-height: 180%;
    letter-spacing: -0.4px;
  }

  .btn p {
    font-size: 1.146vw;
    font-style: normal;
    font-weight: 500;
    line-height: 145.455%;
    letter-spacing: -0.44px;
  }


  // .svg:not(.password-visible-svg) {
  //     padding: 9.5px 12px;
  //     font-size: 14px;
  // }
  // .password-visible-svg{
  //     display: flex;
  //     height: calc(1.5vw + 1rem);
  //     width: 3vw;
  // }
  // .svg-right {
  //     border-radius: 0 18px 18px 0;
  //     border: 1px solid var(--border-light);
  //     border-left: none;
  // }
  .button-group {
    .btn {
      height: 3.016vw;
      color: var(--t-disabled);
      text-align: center;
      font-family: "tt-firs-neue-trial-regular";
      font-size: 1.04vw;
      font-style: normal;
      font-weight: 500;
      line-height: 200%;
      letter-spacing: -0.4px;
      border-radius: 18px;

      &.btn-primary {
        color: #fff !important;
      }

      &:nth-child(2) {
        margin-top: 1.248vw;
      }
    }
  }
}

.currency-setting-card {
  padding: 1.5vw;

  .form-heading {
    font-size: 1.46vw;
    font-style: normal;
    line-height: 135.714%;
    letter-spacing: -0.56px;
  }

  .form-subheading {
    font-size: 1.04vw;
    margin-top: 1.56vw;
  }

  .default-currency {
    display: flex;
    border-radius: 18px;
    border: 1.5px solid var(--border-light);

    .currency-name {
      margin: 7px 5px;
      display: inline-flex;
      padding: 0.624vw 0.52vw;
      align-items: center;
      border-radius: 16px;
      background: rgba(99, 101, 239, 0.10);
      color: var(--bs-primary);
      font-family: "sf-pro-regular";
      font-size: 0.832vw;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
    }

    .currency-symbol {
      margin-right: 0.78vw;
    }
  }

  .search-form-section {
    margin-top: 1.56vw;

    .search-icon-div {
      left: 1vw;

      svg {
        fill: none !important;
      }
    }

    .form-control {
      font-size: 0.832vw;
      height: 3.016vw;
      padding-left: 2vw;
    }
  }

  .countries-list-container {
    max-height: 14.5vw;
    padding-right: 3.8vw;

    .country {
      height: 2.86vw;
      font-family: "sf-pro-regular";
      font-size: 0.832vw;
      font-style: normal;
      line-height: 175%;
      letter-spacing: -0.32px;

      &-symbol {
        color: var(--text-color);
        font-weight: 500;
        width: 18%;
        padding-left: 0.75vw;
      }

      &-name {
        color: var(--t-disabled, #747373);
        font-weight: 400;
        padding-left: 0.5vw;
      }
    }

    .form-radio-btn {
      color: rgba(0, 0, 0, 0.75);
      display: block;
      position: relative;
      line-height: 25px;
      cursor: pointer;
      font-size: 18px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-top: 0.98vw;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
      }

      .checkmark {
        position: absolute;
        top: 0x;
        left: 0px;
        height: 18px;
        width: 18px;
        background-color: rgba(0, 0, 0, 0);
        border-radius: 50%;
        border: 2px solid var(--bs-primary);
        transition: all 0.3s;

        &:after {
          content: "";
          position: absolute;
          display: none;
        }
      }

      input:checked~.checkmark {
        background-color: rgba(0, 0, 0, 0);
        border-color: var(--bs-primary);

        &:after {
          display: block !important;
          top: 2px;
          left: 2px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: var(--bs-primary);
        }
      }
    }
  }
}





// account setting styles Help,settings V2.0 //////////////////////////////////////////////////////////////

.hs-page-explore {
  @media (max-width: 767px) {
    top: -40px;
  }
}

.account-setting-card {
  padding: 30px 20px;
  border-radius: 28px;
  border: 1px solid #FF4D2E;
  background: #FFF;
  box-shadow: 17px 16px 32px 0px rgba(255, 74, 108, 0.15);

  @media (max-width: 767px) {
    padding: 20px 20px;
  }
}

.user-account-name {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .user-account-img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    margin-right: 12px;

    @media (max-width: 767px) {
      width: 25px;
      height: 25px;
      margin-right: 11px;
    }
  }

  .user-account-text {
    color: var(--color-dark);
    font-family: var(--g-font-semibold);
    font-size: 22px;
    line-height: 38px;
    letter-spacing: -0.44px;
    margin-bottom: 0;

    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 28px;
      letter-spacing: -0.36px;
    }
  }
}

.form-heading-large {
  color: var(--color-dark);
  font-family: var(--g-font-semibold);
  font-size: 24px;
  line-height: 38px;
  letter-spacing: -0.48px;

  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: -0.36px;
  }
}

.hs-form-label {
  color: var(--color-dark);
  font-family: var(--g-font-medium);
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.32px;

  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.28px;
  }
}

.hs-form-input {
  padding: 10px 12px;
  color: var(--color-dark);
  font-family: var(--sf-pro-regular);
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.28px;
  background: transparent;
  border-radius: 14px;
  border: 1px solid var(--color-dark-muted);
  min-height: 48px;

  @media (max-width: 767px) {
    padding: 6px 14px;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.28px;
    border-radius: 8px;
    min-height: 38px;
  }

  &.form-control:disabled {
    background-color: transparent !important;
  }
}

.form-subheading-error {
  font-size: 14px;
  font-family: var(--sf-pro-regular);
}