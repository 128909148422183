// affiliate section

// .affiliate-home-section{
//         margin: 20px 0px 50px 0px;
//         h2{
//             font-size: 48px;
//             font-weight: 700;
//             span{
//                 color: var(--color-primary);
//             }
//         }
//         p{
//             font-size: 24px;
//             font-weight: 600;
//             color: var(--color-dark);
//             margin: 30px 0px;
//         }
//         .btn{

//                 width: -moz-fit-content;
//                 width: fit-content;
//                 padding: 15px 35px;
//                 border-radius: 100px;
//                 font-size: 18px;
//                 background-color: var(--color-primary);
//                 text-decoration: none;
//                 color: var(--color-white);
//                 border: 1px solid var(--color-primary);
//         }

// }

.landing-affiliate-spaces {
  margin-bottom: 80px;

  @media screen and (max-width:767px) {
    margin-bottom: 0px;
  }
}



.affiliate-home-container {
  .affiliate-home-container-flex {
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width:767px) {
      justify-content: center;
      flex-direction: column;
    }

    .affiliate-home-text-container {
      flex: 1;
      align-self: center;

      .affiliate-home-text-head {
        color: var(--color-dark);
        text-align: center;
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        line-height: 136%;
        margin-bottom: 25px;

        @media screen and (max-width:1399px) {
          font-size: 38px;
          font-style: normal;
          font-weight: 700;
          line-height: 136%;
          margin-bottom: 20px;
        }

        @media screen and (max-width:991px) {
          font-size: 26px;
          margin-bottom: 18px;
        }

        @media screen and (max-width:767px) {
          font-size: 24px;
          font-style: normal;
          font-weight: 700;
          line-height: 136%;
        }
      }

      .affiliate-home-text-subhead {
        color: var(--color-dark);
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.72px;
        margin-bottom: 40px;

        @media screen and (max-width:1399px) {
          font-size: 26px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          letter-spacing: -0.52px;
          margin-bottom: 38px;
        }

        @media screen and (max-width:991px) {
          font-size: 18px;
          margin-bottom: 25px;
        }

        @media screen and (max-width: 767px) {
          font-size: 16px;
          line-height: 1.5;
        }

        @media screen and (max-width: 411px) {
          font-size: 15px;
        }
      }
    }

    .affiliate-home-img-container {
      flex-shrink: 0;

      @media screen and (max-width:1199px) {
        max-width: 300px;
      }

      @media screen and (max-width:767px) {
        max-width: 354px;
      }
    }

    .btn-join-ecoroam {
      color: var(--color-primary);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.4px;
      border-radius: 100px;
      border: 1px solid var(--color-primary);
      min-height: 52px;
      min-width: 250px;

      @media screen and (max-width:1399px) {
        font-size: 18px;
        min-height: 46px;
        letter-spacing: -0.28px;
        padding: 5px 20px;
      }

      @media screen and (max-width: 767px) {
        min-height: 38px;
        font-size: 14px;
      }
    }
  }

}


// multiple-esim-bar-container ////////////////////

.multiple-esim-bar-container {
  padding: 3rem 0 0rem;

  @media screen and (max-width: 1679px) {
    padding: 3rem 0 4.5rem;
  }

  @media screen and (max-width:767px) {
    padding: 1.5rem 0;
  }

  .container {
    padding: 0;
  }

  .multiple-esim-bar-container-flex {
    border-radius: 28px;
    border: 1px solid var(--color-primary);
    background: rgba(255, 255, 255, 0.50);
    box-shadow: 17px 16px 32px 0px rgba(255, 74, 108, 0.15);
    display: flex;
    padding: 38px 38px;
    justify-content: space-between;
    align-items: center;
    gap: 61px;

    @media screen and (max-width:1399px) {
      padding: 18px;
    }

    @media screen and (max-width:767px) {
      flex-wrap: wrap;
      justify-content: center;
      align-self: center;
      gap: 20px;
      border-radius: 16px;
    }

    .multiple-esim-bar-head {
      font-size: 36px;
      font-style: normal;
      font-weight: 600;
      line-height: 136%;
      margin-bottom: 0;
      background: var(--line-grade, linear-gradient(270deg, #FF4D2E -0.01%, #FF48AB 99.64%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @media screen and (max-width:1399px) {
        font-size: 24px;
      }

      @media screen and (max-width:991px) {
        font-size: 20px;
      }

      @media screen and (max-width:767px) {
        text-align: center;
        font-size: 22px;
      }
    }

    .multiple-esim-bar-btn {
      background-color: var(--color-primary);
      color: var(--color-white);
      display: flex;
      padding: 6px 35px;
      justify-content: center;
      align-items: center;
      border-radius: 100px;
      color: var(--White, var(--system-white, #FFF));
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: -0.48px;

      @media screen and (max-width:1399px) {
        font-size: 18px;
      }

      @media screen and (max-width:767px) {
        width: 100%;
        min-height: 32px;
        font-size: 14px;
      }
    }
  }
}


// ecoroam esim features /////////////////////////////////////////

.landing-esim-features-spaces {
  margin-bottom: 60px;

  @media screen and (max-width:991px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width:500px) {
    margin-bottom: 30px;
  }
}



.ecoroam-esim-features {
  margin-top: 1.25rem;

  @media screen and (max-width: 767px) {
    margin-top: 0;
  }

  .ecoroam-esim-features-heading {
    color: var(--color-dark);
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    margin-bottom: 40px;

    @media screen and (max-width:1399px) {
      font-size: 36px;
      margin-bottom: 32px;
    }

    @media screen and (max-width:767px) {
      font-size: 24px;
      text-align: center;
      line-height: normal;
      margin-bottom: 1.5rem;
    }
  }

  .ecoroam-esim-features-flex {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: space-between;
    gap: 36px;
    flex-wrap: wrap;

    @media screen and (max-width:1199px) {
      gap: 20px;
    }

    @media screen and (max-width: 767px) {
      gap: 8px;
    }

    .ecoroam-esim-features-flex-box {
      display: flex;
      width: 296px;
      padding: 31px 38px 30px 39px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 19px;
      flex-shrink: 0;
      border-radius: 28px;
      background: #FFF3F1;
      min-height: 236px;

      @media screen and (max-width:1399px) {
        width: 252px;
      }

      @media screen and (max-width:1199px) {
        width: 219px;
        padding: 23.777px 34.419px 21px 34px;
        min-height: 190px;
      }

      @media screen and (max-width:991px) {
        width: 210px;
      }

      @media screen and (max-width: 767px) {
        width: 165px;
      }

      @media screen and (max-width:500px) {
        padding: 22.912px 27.259px 21px 29px;
        min-height: 175px;
        border-radius: 20px;
      }

      @media screen and (max-width:380px) {
        width: 145px;
      }


      .ecoroam-esim-features-flex-box-svg {
        width: 102px;
        height: 102px;

        @media screen and (max-width:1399px) {
          width: 76px;
          height: 76px;
        }
      }

      .ecoroam-esim-features-flex-box-text {
        color: #070707;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.4px;
        margin-bottom: 0;
        font-family: Montserrat;

        @media screen and (max-width:1399px) {
          font-size: 18px;
        }

        @media screen and (max-width:500px) {
          font-size: 14px;
        }
      }
    }

    .ecoroam-esim-features-flex-box-last {
      flex: 1;
      border-radius: 22px;
      border: 1px solid rgba(255, 77, 46, 0.33);
      background: linear-gradient(90deg, #FF4D2E -47.52%, #FF48AB 99.99%);
      display: flex;
      padding: 30px;
      justify-content: flex-end;
      align-items: end;
      text-align: right;
      align-self: stretch;
      max-width: 758px;

      @media screen and (max-width: 767px) {
        display: none;
      }

      @media screen and (max-width:500px) {
        padding: 20px 16px;
        justify-content: flex-start;
        align-items: start;
        min-height: unset;
      }

      .ecoroam-esim-features-flex-box-text {
        color: var(--color-white);
        text-align: right;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 179%;
        max-width: 530px;
        font-family: Montserrat;

        @media screen and (max-width:1399px) {
          font-size: 20px;
        }

        @media screen and (max-width:1199px) {
          font-size: 18px;
        }

        @media screen and (max-width:500px) {
          max-width: 233px;
          text-align: left;
          font-size: 14px;
        }
      }
    }
  }
}

// How ecoroam eSIM Works /////////////////
.how-ecoroam-esim-works-spaces {
  margin-bottom: 60px;

  @media screen and (max-width:991px) {
    margin-bottom: 40px;
  }

  @media screen and (max-width:500px) {
    margin-bottom: 20px;
  }
}


.how-ecoroam-esim-works-container {
  border-radius: 105px;
  border: 4px solid var(--color-primary);
  padding-top: 40px;
  padding-bottom: 20px;


  @media screen and (max-width:1399px) {
    border-radius: 62px;
    border: 3px solid var(--color-primary);
  }

  @media screen and (max-width:500px) {
    border-radius: 38px;
    border: 2px solid var(--color-primary);
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .how-ecoroam-esim-works-head {
    color: var(--color-dark);
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    margin-bottom: 40px;

    @media screen and (max-width:1399px) {
      font-size: 28px;
      margin-bottom: 30px;
    }

    @media screen and (max-width:500px) {
      font-size: 20px;
      margin-bottom: 5px;
    }
  }

  .how-ecoroam-esim-works-box-flex {

    // display: flex;
    // gap: 20px;
    // justify-content: space-between;
    .how-ecoroam-esim-works-box {
      padding: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;
      // width: 296px;

      @media screen and (max-width:767px) {
        padding: 20px;
      }

      .how-ecoroam-esim-works-box-svg-container {
        margin-bottom: 24px;

        @media screen and (max-width:1399px) {
          margin-bottom: 17px;
        }

        svg {
          width: 80px;
          height: 80px;

          @media screen and (max-width:1399px) {
            width: 60px;
            height: 60px;
          }
        }
      }

      .how-ecoroam-esim-works-subhead {
        color: var(--color-dark);
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.52px;
        font-family: Montserrat;

        @media screen and (max-width:1399px) {
          font-size: 18px;
        }

        @media screen and (max-width:991px) {
          max-width: 190px;
        }


        @media screen and (max-width:500px) {
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.28px;
        }
      }
    }
  }

}


// testimonial section container ////////////////////////////////
.testimonial-slick-spaces {
  margin-bottom: 50px;

  @media screen and (max-width:767px) {
    margin-bottom: 1.5rem;
  }
}


.testimonial-slick-section {
  border-radius: 77px;
  background: #FFF0F3;
  padding: 35px 80px 30px 80px;

  @media screen and (max-width:1399px) {
    border-radius: 57.093px;
    padding: 26px 82px;
    border-radius: 60px;
  }

  @media screen and (max-width:767px) {
    padding: 20px 40px;
    border-radius: 38px;
  }

  @media screen and (max-width:500px) {
    padding: 20px 25px;
    border-radius: 38px;
  }

  .testimonial-slick-section-head {
    color: var(--color-dark);
    text-align: center;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.72px;
    margin-bottom: 30px;
    font-family: Montserrat;

    @media screen and (max-width:1399px) {
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.52px;
      margin-bottom: 24px;
    }

    @media screen and (max-width:500px) {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 136%;
      margin-bottom: 20px;
    }
  }

  .testimonial-slick-main {
    .testimonial-slick-section-title {
      color: var(--color-dark);
      text-align: center;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.52px;
      margin-bottom: 20px;
      font-family: Montserrat;

      @media screen and (max-width:1399px) {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.36px;
        margin-bottom: 18px;
      }
    }

    .testimonial-slick-section-discription {
      color: var(--color-dark);
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 171%;
      margin-bottom: 10px;
      font-family: "Montserrat", sans-serif;

      @media screen and (max-width:1399px) {
        font-size: 16px;
        margin-bottom: 12px;
      }


      @media screen and (max-width:500px) {
        font-size: 13px;
        margin-bottom: 18px;
      }
    }

    .testimonial-slick-section-auther {
      color: #070707;
      text-align: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: -0.4px;
      font-family: Montserrat;

      @media screen and (max-width:1399px) {
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        letter-spacing: -0.3px;
      }
    }
  }
}

// popular destination card container /////////////////////

.country-card-main {
  border-radius: 20px;
  background: var(--color-white);
  padding: 15px;
  margin-bottom: 20px;


  @media screen and (max-width:1399px) {
    padding: 20px;
  }

  @media screen and (max-width:500px) {
    padding: 8px;
    border-radius: 13px;
    margin-bottom: 10px;
  }

  .country-card-main-flag-name-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 13px;

    .country-card-main-flag-container {
      width: 64px;
      height: 46px;
      display: flex;
      align-items: center;
      gap: 15px;
      flex-shrink: 0;

      @media screen and (max-width:500px) {
        width: 50px;
        height: 36px;
      }

      .country-card-main-flag {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 9px;

        @media screen {
          border-radius: 6px;
        }
      }
    }

    .country-card-main-name-container {
      flex: 1;

      .country-card-main-name {
        color: var(--color-dark);
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 40px;
        letter-spacing: -0.36px;
        margin-bottom: 0;

        @media screen and (max-width:1399px) {
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px;
          letter-spacing: -0.32px;
        }
      }
    }
  }

  .country-card-bottom-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .country-card-bottom-left {
      .country-card-bottom-left-text {
        color: #4F4F4F;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: -0.36px;
        max-width: 152px;
        margin-bottom: 0;

        @media screen and (max-width:1399px) {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          letter-spacing: -0.32px;
          max-width: 145px;
        }

        @media screen and (max-width:500px) {
          max-width: unset;
        }

        .country-card-bottom-left-text-count {
          color: var(--color-primary);
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px;
          letter-spacing: -0.36px;
        }
      }
    }

    .country-card-arrow-container {
      border: 1px solid var(--color-primary);
      width: 46px;
      height: 46px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width:500px) {
        width: 30px;
        height: 30px;
      }

      svg {
        @media screen and (max-width:500px) {
          width: 18.5px;
          height: 18.5px;
        }
      }
    }
  }
}

.region-card-main {
  // border-radius: 20px;
  // background: var(--color-white);
  // padding: 15px;
  // margin-bottom: 20px;

  // .country-card-main-flag-name-container{
  //     display: flex;
  //     align-items: center;
  //     gap: 15px;
  //     margin-bottom: 13px;

  //     .country-card-main-flag-container{
  //         width: 56px;
  //         height: 46px;
  //         display: flex;
  //         align-items: center;
  //         gap: 15px;
  //         flex-shrink: 0;

  //         .country-card-main-flag{
  //             width: 100%;
  //             height: auto;
  //             border-radius: 9px;
  //         }
  //     }

  //     .country-card-main-name-container{
  //         flex: 1;
  //         .country-card-main-name{
  //             color: var(--color-dark);
  //             font-size: 18px;
  //             font-style: normal;
  //             font-weight: 600;
  //             line-height: 40px;
  //             letter-spacing: -0.36px;
  //             margin-bottom: 0;
  //         }
  //     }
  // }

  // .country-card-bottom-container{
  //     display: flex;
  //     align-items: end;

  //     .country-card-bottom-left{
  //         .country-card-bottom-left-text{
  //             color: #4F4F4F;
  //             font-family: Montserrat;
  //             font-size: 18px;
  //             font-style: normal;
  //             font-weight: 400;
  //             line-height: 40px;
  //             letter-spacing: -0.36px;
  //             max-width: 152px;
  //             margin-bottom: 0;

  //             .country-card-bottom-left-text-count{
  //                 color: var(--color-primary);
  //                 font-family: Montserrat;
  //                 font-size: 18px;
  //                 font-style: normal;
  //                 font-weight: 600;
  //                 line-height: 40px;
  //                 letter-spacing: -0.36px;
  //             }
  //         }
  //     }

  //     .country-card-arrow-container{
  //         margin-left: auto;
  //         margin-top: auto;
  //         border: 1px solid var(--color-primary);
  //         width: 46px;
  //         height: 46px;
  //         border-radius: 100%;
  //         display: flex;
  //         justify-content: center;
  //         align-items: center;
  //     }
  // }

  border-radius: 20px;
  background: var(--color-white);
  padding: 15px;
  margin-bottom: 20px;
  min-height: 190px;


  @media screen and (max-width:1399px) {
    padding: 20px;
    min-height: 175px;
  }

  @media screen and (max-width:767px) {
    min-height: unset;
  }

  @media screen and (max-width:500px) {
    padding: 8px;
  }

  .country-card-main-flag-name-container {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 13px;

    .country-card-main-flag-container {
      width: 64px;
      height: 46px;
      display: flex;
      align-items: center;
      gap: 15px;
      flex-shrink: 0;

      @media screen and (max-width:1399px) {
        width: 50px;
        height: 40px;
      }

      @media screen and (max-width:500px) {
        width: 50px;
        height: 36px;
      }

      .country-card-main-flag {
        width: 100%;
        height: auto;
        border-radius: 9px;
      }
    }

    .country-card-main-name-container {
      flex: 1;

      .country-card-main-name {
        color: var(--color-dark);
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: -0.32px;
        margin-bottom: 0;
      }
    }
  }

  .country-card-bottom-container {
    display: flex;
    align-items: end;

    .country-card-bottom-left {
      .country-card-bottom-left-text {
        color: #4F4F4F;
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
        letter-spacing: -0.36px;
        max-width: 152px;
        margin-bottom: 0;

        @media screen and (max-width:1399px) {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 34px;
          letter-spacing: -0.32px;
          max-width: 145px;
        }

        @media screen and (max-width:500px) {
          max-width: unset;
        }

        .country-card-bottom-left-text-count {
          color: var(--color-primary);
          font-family: Montserrat;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 40px;
          letter-spacing: -0.36px;
        }
      }
    }

    .country-card-arrow-container {
      margin-left: auto;
      margin-top: auto;
      border: 1px solid var(--color-primary);
      width: 46px;
      height: 46px;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (max-width:500px) {
        width: 30px;
        height: 30px;
      }

      svg {
        @media screen and (max-width:500px) {
          width: 18.5px;
          height: 18.5px;
        }
      }
    }
  }
}


// common plan and destination head section ////////////////////

.common-plan-head-container {
  padding: 44px;

  @media screen and (max-width:1399px) {
    padding: 30px;
  }

  @media screen and (max-width:767px) {
    padding: 20px 0;
  }

  .common-plan-head {
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 136%;
    margin-bottom: 14px;

    @media screen and (max-width:1399px) {
      font-size: 30px;
      font-style: normal;
      font-weight: 700;
      line-height: 136%;
      letter-spacing: 0.3px;
      margin-bottom: 12px;
    }

    @media screen and (max-width:767px) {
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 136%;
      letter-spacing: 0.24px;
      margin-bottom: 14px;
    }
  }

  .common-plan-head-primary {
    background: var(--line-grade, linear-gradient(270deg, #FF4D2E -0.01%, #FF48AB 99.64%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .common-plan-subhead {
    color: var(--color-dark);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 136%;

    @media screen and (max-width:1399px) {
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 179%;
    }

    @media screen and (max-width:767px) {
      font-size: 16px;
      font-style: normal;
      margin-bottom: 0;
    }
  }
}


.common-plan-page-description-container {
  border-radius: 28px;
  border: 1px solid #FF4D2E;
  background: #FFF;
  box-shadow: 17px 16px 32px 0px rgba(255, 74, 108, 0.15);
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  @media screen and (max-width:1399px) {
    border-radius: 18px;
    padding: 18px;
    margin-bottom: 25px;
  }

  @media screen and (max-width:767px) {
    border-radius: 16px;
    box-shadow: 17px 16px 32px 0px rgba(255, 74, 108, 0.15);
    padding: 14px;
    margin-bottom: 14px;
  }

  .common-plan-page-description-head-text {
    color: var(--color-dark);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 144%;
    margin-bottom: 19px;
  }

  .common-plan-page-description-text {
    font-family: "Montserrat", sans-serif;
    color: var(--color-dark);
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 144%;
    margin-bottom: 0;

    @media screen and (max-width:1399px) {
      font-size: 18px;
      font-weight: 600;
    }

    @media screen and (max-width:767px) {
      font-size: 15px;
      line-height: 170%;
    }

    @media screen and (max-width: 410px) {
      font-size: 14px;
      letter-spacing: -0.5px;
    }
  }
}

.common-esim-bar-spaces {
  margin-bottom: 54px;

  @media screen and (max-width:767px) {
    margin-bottom: 28px;
  }
}

// global-list-card-main ////////////////

.global-list-card-main {
  border-radius: 28px;
  border: 1px solid #FFD4CD;
  background: #FFF;
  margin-bottom: 29px;
  padding: 22px;
}

.plan-main-card-global {
  border-radius: 28px;
  border: 1px solid #FFD4CD;
  background: var(--color-white);
  padding: 22px;
  margin-bottom: 30px;
  position: relative;

  @media screen and (max-width:1399px) {
    padding: 16px;
    border-radius: 20px;
    margin-bottom: 25px;
  }

  .on-sale-flag-container {
    position: absolute;
    right: 20px;
    top: 0;
    display: flex;
    gap: 0rem;

    .on-sale-container {
      background-color: color-mix(in srgb, var(--color-primary) 10%, transparent);
      border-radius: 0 0 7px 7px;
      height: fit-content;
      padding: 0.25rem 0.75rem;

      p {
        margin-bottom: 0;
        background: linear-gradient(359.99deg, #FF4D2E -61.65%, #FF48AB 99.94%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Montserrat", sans-serif;
        font-weight: 700;
        font-size: 14px;

        @media screen and (max-width: 767px) {
          font-size: 12px;
        }
      }
    }

    .plan-main-card-flag-img {
      margin-top: -12px;
      width: 70px;
      height: auto;

      @media screen and (max-width:1399px) {
        width: 50px;
      }

      @media screen and (max-width: 767px) {
        margin-top: -8px;
      }
    }
  }

  .plan-main-card-highlight {
    color: #828282;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 6px;

    @media screen and (max-width:1399px) {
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.2px;
      margin-bottom: 4.5px;
    }
  }

  .plan-main-card-plan-name {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: -0.48px;
    margin-bottom: 18px;
    max-width: 250px;

    @media screen and (max-width:1399px) {
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: -0.36px;
      margin-bottom: 13px;
    }

    @media screen and (max-width:1199px) {
      max-width: 200px;
    }

    @media screen and (max-width: 575px) {
      max-width: 155px;
    }
  }

  .plan-main-card-table {
    display: flex;
    gap: 18px;
    flex-direction: column;
    margin-bottom: 24px;

    @media screen and (max-width:1399px) {
      gap: 13px;
    }

    .plan-main-card-table-text {
      color: var(--color-dark);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 144%;

      @media screen and (max-width:1399px) {
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 144%;
      }
    }

    .plan-main-card-table-row {
      display: flex;
      align-items: center;

      .plan-main-card-table-row-head {
        width: 180px;
        flex-shrink: 0;
        display: flex;
        align-items: center;

        @media screen and (max-width:1399px) {
          width: 135px;
        }

        .plan-main-card-table-row-head-svg {
          width: 24px;
          height: 24px;
          margin-right: 9px;

          @media screen and (max-width:1399px) {
            width: 18px;
            height: 18px;
            margin-right: 6.5px;
          }
        }
      }
    }
  }

  .plan-main-card-buy-btn {
    border-radius: 100px;
    background: linear-gradient(90deg, #FF4D2E -61.65%, #FF48AB 99.94%);
    padding: 10px 20px;
    width: 100%;
    color: #FFF;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 144%;

    @media screen and (max-width:1399px) {
      padding: 8px 10px;
      color: var(--system-white, #FFF);
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 144%;
    }
  }
}

// instalation guide-modal ////////////////////////////////////

.installation-guide-w {
  max-width: 1250px;

  @media screen and (max-width:1399px) {
    max-width: 942px;
  }

  @media screen and (max-width:991px) {
    max-width: 742px;
  }
}


// simmer effect /////////////////////////////////////////////////

.simmer {
  // width: 100%;
  // height: 100vh; /* Full viewport height */
  background: linear-gradient(135deg, #e0e0e0 25%, #f5f5f5 50%, #e0e0e0 75%);
  background-size: 200% 200%;
  animation: shimmer 3s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }

  100% {
    background-position: 200% 0;
  }
}


// plan-list-choose-package-head-container //////

.plan-list-choose-package-head-container {
  text-align: center;
  margin-bottom: 37.5px;

  @media screen and (max-width:1399px) {
    margin-bottom: 24px;
  }

  @media screen and (max-width:767px) {
    margin-bottom: 20px;
  }

  .plan-list-choose-package-head {
    color: var(--color-dark);
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 136%;

    @media screen and (max-width:1399px) {
      font-size: 20px;
      line-height: 179%;
    }

    @media screen and (max-width:767px) {
      font-size: 16px;
    }

    @media screen and (max-width: 410px) {
      font-size: 14px;
    }
  }
}


.modal-md-vw-45-pd {
  max-width: 600px;
}


// .privacy-policy-section {

//   .g-page-title-section {
//     min-height: 300px;

//     @media screen and (max-width:1199px) {
//       min-height: 330px !important;
//     }

//     @media screen and (max-width:767px) {
//       min-height: 155px !important;
//     }
//   }
// }

.privacy-policy-section.page-padding-top-medium {
  @media screen and (max-width:1199px) {
    padding-top: 0 !important;
  }

  @media screen and (max-width: 767px) {
    padding-top: 2rem !important;
  }
}

.multi-dropdown-parent {
  .multiselect-dropdown {

    .dropdown-multiselect__caret {
      top: 50% !important;
      transform: translateY(-50%) rotate(0deg) !important;

      &::before {
        border-style: solid !important;
        border-width: 0.15em 0.15em 0 0 !important;
        display: inline-block !important;
        height: 0.45em !important;
        left: 0 !important;
        position: relative !important;
        top: 0.45em !important;
        vertical-align: top !important;
        width: 0.45em !important;
        transform: rotate(135deg);
        border-right: 0.15em solid !important;
      }
    }

    .dropdown-multiselect--active .dropdown-multiselect__caret {
      transform: translateY(-50%) rotate(180deg) !important;
    }

    .dropdown-btn {
      min-height: 48px !important;
      color: var(--color-dark);
      font-family: var(--sf-pro-regular);
      font-size: 16px;
      line-height: 28px;
      letter-spacing: -0.28px;
      background: transparent;
      border-radius: 14px !important;
      border: 1px solid var(--color-dark-muted) !important;
      display: flex !important;
      align-items: center;

      @media screen and (max-width: 767px) {
        font-size: 14px;
        min-height: 38px !important;
        border-radius: 8px !important;
      }

      .selected-item-container {

        .selected-item {
          border: none !important;
          background: unset !important;
          padding: 0 5px;
          color: var(--color-dark) !important;
          max-width: 150px !important;

          &:hover {
            box-shadow: none !important;
          }

          &>span {
            text-wrap: nowrap;
          }

          a {
            color: var(--color-dark) !important;
          }
        }
      }
    }

    .dropdown-list {
      padding: 0.5rem !important;
    }
  }

  .multiselect-item-checkbox {
    padding: 0.5rem 1.5rem !important;

    &:hover {
      background: color-mix(in srgb, var(--color-primary) 20%, transparent) !important;
      border-radius: 12px;
    }

    input[type=checkbox] {

      &+div {
        padding-left: 0 !important;

        @media screen and (max-width: 767px) {
          font-size: 14px;
        }

        &::before {
          right: 0;
          left: unset !important;
          border: 1px solid #000000 !important;
          width: 20px !important;
          height: 20px !important;

          @media screen and (max-width: 767px) {
            width: 16px !important;
            height: 16px !important;
          }
        }

        &::after {
          right: 5px;
          left: unset !important;
          border-width: 0 0 2px 2px !important;
          width: 11px !important;
          height: 4px !important;
          margin-top: -3px !important;

          @media screen and (max-width: 767px) {
            right: 3px;
            margin-top: -4px !important;
          }
        }
      }

      &:checked+div:before {
        background: transparent !important;
        border: 1px solid var(--color-primary) !important;
      }

      &:checked+div::after {
        border-color: var(--color-primary) !important;
      }
    }
  }
}


.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
  background-color: var(--color-primary) !important;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
  border-color: var(--color-primary) !important;
}

.mat-mdc-radio-button {
  margin-right: 1rem;
}