/// Backgrounds //////////
.g-bg-primary {
  background-color: var(--color-primary);
}

.g-bg-white {
  background-color: var(--color-white) !important;
}






.g-color-dark {
  color: var(--color-dark) !important;
}

.g-color-white {
  color: var(--color-white);
}

.bg-primary {
  background-color: var(--color-primary) !important;
}






// Text Colors ///////////////

.g-color-primary {
  color: var(--color-primary) !important;
}

.btn-primary {
  background: var(--color-primary);
}

.g-color-grey {
  color: var(--color-grey);
}